<template>
  <v-app-bar
    v-scroll="onScroll"
    :color="!isScrolling ? 'transparent' : 'grey lighten-3'"
    :flat="!isScrolling"
    app
    :dark="!isScrolling ? true : false"
    hide-on-scroll
  >
    <v-img
      class="shrink"
      contain
      max-width="12%"
      :src="!isScrolling ? '/static/small_eba_logo_trans_blue_white.png' : '/static/small_eba_logo_transparent.png'"
    />

    <v-spacer />

    <v-toolbar-items>
      <v-btn
        href="https://diga.ee/#/login"
        text
      >
        Sisene kliendikeskkonda
      </v-btn>
    </v-toolbar-items>
  </v-app-bar>
</template>

<script>
  // Utilities
  import { mapMutations } from 'vuex'

  export default {
    data: () => ({
      isScrolling: false

    }),

    methods: {
      ...mapMutations(['toggleDrawer']),
      onScroll () {
        this.isScrolling = (window.pageYOffset ||
          document.documentElement.scrollTop || 0) > 25
      }
    }
  }
</script>
